<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b"><el-button type="primary" size="small" v-right-code="'Ruleofwhole:Update'" @click="addData">新增</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" v-right-code="'Ruleofwhole:Delete'" @click="delData">删除</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" v-right-code="'Ruleofwhole:Setstatus'" @click="setStatusData(1)">启用</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" v-right-code="'Ruleofwhole:Setstatus'" @click="setStatusData(0)">停用</el-button> </div>
            </div>
            <p slot="elList">
                <el-table ref="dataListTable" :data="dataSource.Result" border @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" show-overflow-tooltip
                                     :key="index"
                                     :prop="col.FieldName"
                                     :label="col.DisplayName"
                                     :render-header="bindFilter(queryParam,col)"
                                     :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                     :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                     v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName==='RuleName'"><span type="text" @click="rowClick(scope.row)" style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</span></span>
                            <span v-else>  {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>
<script>
    export default {
        mounted() {
            this.Event.$on("reloadDataPageList", () => this.reloadPageList());
            this.Event.$on("onAddData", () => this.addData());
            this.initialize();
        },
        data() {
            return {
                multipleSelection: [],
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10
                },
                dataSource: {
                    ColDefs: {},
                    Result: [],
                    TotalCount: 0
                }
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isButtonlDisplay: false,
            },
            option: {}
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("onCheckRow", this.multipleSelection);
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                this.$ajax.query("omsapi/ruleOfWhole/findList", "post", param, data => {
                    _this.dataSource = data;
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            rowClick(row) {
                this.$ajax.send("omsapi/ruleOfWhole/getById", "get", { id: row.Id }, (data) => {
                    this.Event.$emit("clearEditDataForm");
                    this.onChangeEditDataSource(data.Result);
                });
            },
            delData() {
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox(`未选择要删除的数据.`, "error");
                    return false;
                }

                var This = this;
                this.Utils.confirm({
                    title: "是否确认删除数据?",
                    content: "删除后数据不可恢复,该数据将不可使用,是否确认删除?"
                }, () => {
                    this.$ajax.send("omsapi/ruleOfWhole/delete", "post", this.multipleSelection, (data) => {
                        This.onPageChange(This.queryParam);
                        This.Utils.messageBox("数据删除成功.", "info");
                    });
                }, () => {
                    This.Utils.messageBox("已取消删除.", "info");
                });
            },
            setStatusData(status) {
                var _this=this;
                var statusName = status === 1 ? "启用" : "停用";
                var notstatusName = status === 1 ? "停用" : "启用";
                
                if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                    this.Utils.messageBox("未选择要"+statusName+"的数据.", "error");
                    return false;
                }
                var filterRows = this.multipleSelection.filter(p => p.Status === status);
                if(filterRows.length > 0) {
                    this.Utils.messageBox("只能选择"+notstatusName+"状态的数据.", "error");
                    return false;
                }

                var This = this;
                this.Utils.confirm({
                    title: "是否确认"+statusName+"数据?",
                }, () => {
                    this.$ajax.send("omsapi/ruleOfWhole/setStatus", "post", this.multipleSelection, (data) => {
                        This.onPageChange(This.queryParam);
                        if (data.IsSuccess) {
                            _this.Utils.messageBox(data.OperationDesc, "success");
                        } else {
                            _this.Utils.messageBox(data.OperationDesc, "error");
                        }
                    });
                }, () => {
                    This.Utils.messageBox("已取消"+statusName+".", "info");
                });
            },
            addData() {
                this.$ajax.send("omsapi/ruleOfWhole/getEmptyDto", "get", {}, (data) => {
                    this.onChangeEditDataSource(data.Result);
                });
            },
            onChangeEditDataSource(data) {
                this.config.isDetailDisplay = true;
                this.$emit("onChangeEditDataSource", data);
            },
        }
    }
</script>
<style>

</style>